.search-dropdown {
    width: 100%;
    font-family: FontAwesome, 'Open Sans', Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
}

.search-dropdown button.list-group-item {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.search-dropdown div.list-group {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(131, 129, 129, 0.19);
}
