:root {
  --YNCN-yellow: #f8cd6a;
  --YNCN-yellow-light: #fdefce;
  --YNCN-blue: #4fafbd;
  --YNCN-blue-light: #c8e5ea;
  --YNCN-red: #f56767;
  --YNCN-red-light: #fde7e7;
  --YNCN-green: #72bf95;
  --YNCN-green-light: #dcefe5;
  --Panel-color: #ebf4fb;
  --Panel-color-border: #ebf4fb;
  --Gray-button-text: #6c757c;
  --Gray-button: #d3d3d3;
}

.backButtonIcon {
  color: var(--Gray-button-text);
  padding: 0.3rem;
}

.topPanelBackgroundColor {
  background-color: var(--Panel-color);
}

.topPanelBorder {
  border: 0.4rem solid var(--Panel-color-border);
}

.industryColor {
  background-color: var(--YNCN-green) !important;
}

.yearColor {
  background-color: var(--YNCN-blue) !important;
}

.disciplineColor {
  background-color: var(--YNCN-red) !important;
}

.tierColor {
  background-color: var(--YNCN-yellow) !important;
}

.locationColor {
  background-color: var(--YNCN-green) !important;
}

.button-custom {
  border: 0.3rem solid var(--Gray-button) !important;
  background-color: transparent !important;
}

.button-custom:hover {
  color: white !important;
  background-color: var(--Gray-button) !important;
}

.horizontal-line {
  border: none;
  border-top: 4px solid var(--Gray-button-text);
  margin: 0 auto;
  width: 80%;
}
