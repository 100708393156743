.show-on-hover {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.show-on-hover:hover {
  opacity: 1;
}

.search-filters {
  position: fixed;
  width: 100%;
  z-index: 1;
}