.yncn-yes {
    background-color: rgb(108,195,141,1) !important;
    border: none !important;
}

.yncn-yes:hover {
    background-color: rgb(108,195,141,0.7) !important;
}

.yncn-no {
    background-color: rgb(231,126,108,1) !important;
    border: none !important;
}

.yncn-no:hover {
    background-color: rgb(231,126,108,0.7) !important;
}
