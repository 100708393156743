.button,
.button.selectedblue,
.button.selectedred,
.button.selectedgreen,
.button.selectedyellow {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  color: black;
  border-radius: 15px 5px 15px 5px;
  background-color: white;
  border: 1px solid rgb(177, 176, 176);
  margin: 9px 0.125rem;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2),
    0 2px 10px 0 rgba(131, 129, 129, 0.19);
  text-align: center;
  transition-duration: 0.3s;
}

.masked {
  mask-image: linear-gradient(to left, transparent, transparent);
}

.button:hover {
  background-color: #d3d3d3;
  color: white;
  border: none;
}

.button.selectedblue {
  background-color: #50b1b6;
  color: white;
  border: none;
}

.button.selectedred {
  background-color: #e68272;
  color: white;
  border: none;
}

.button.selectedgreen {
  background-color: #6ac393;
  color: white;
  border: none;
}

.button.selectedyellow {
  background-color: #f4cd68;
  color: white;
  border: none;
}

.icontext-wrapper {
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-icon {
  height: 14px;
  padding-right: 6px;
  text-align: justify;
}

.button-text {
  font-size: 12px;
}

.company-filters {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
}

.year-dropdown {
  width: 8rem;
  max-width: 100%;
  margin-right: 1rem;
}

.discipline-dropdown {
  width: 15rem;
  max-width: 100%;
}

.horizontal-slider {
  padding: 0rem 1rem;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

@media only screen and (max-width: 1350px) {
  .horizontal-slider {
    mask-image: linear-gradient(to right,
        transparent,
        black 4%,
        black 76%,
        transparent);
  }
}

/* This is for chrome/opera/safari */
.horizontal-slider::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
}

.standard-partners {
  flex-grow: 1;
  overflow: auto;
}

.standard-partners .card-in-scroll {
  display: inline-block;
  border: none;
  white-space: pre-wrap;
  align-self: center;
}

.standard-partners .horizontal-slider {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

/* This is for chrome/opera/safari */
.standard-partners .horizontal-slider::-webkit-scrollbar {
  display: none;
}

.p-dropdown .p-dropdown-label {
  padding: 4px;
  font-size: 1rem;
  padding-left: 10px;
}

.p-multiselect .p-multiselect-label {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 1rem;
}

.p-icon {
  height: 1rem !important;
}

.p-dropdown-trigger[aria-expanded="true"] {
  transform: rotate(180deg);
}

.p-dropdown, .p-multiselect {
  border-radius: 15px 5px 15px 5px !important;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2),
    0 2px 10px 0 rgba(131, 129, 129, 0.19);
}

.p-dropdown-items {
  text-align: center;
}