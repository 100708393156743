.floor-dropdown {
  width: 9rem;
  margin-right: 0;
}

.floor-dropdown .p-dropdown-label {
  font-weight: bold;
}

.bottom-right-dropdown {
  position: fixed;
  bottom: 10dvh;
  right: 5%;
}
