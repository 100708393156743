.interactive-icon {
    position: relative;
    animation: bounce-animation 5s ease infinite;
    border:2px solid #000000;
    border-radius:7px;
}

.icon-highlight-wrapper {
    border: 1px solid transparent;
}

.icon-highlight-rect{
    stroke-width: 8px;
    stroke-linecap: round;
    fill: none;
    stroke:rgb(43, 222, 221);
}

@keyframes bounce-animation {
    0%, 8%, 20%, 32%, 40% {
        transform: translateY(0);
        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.6));
    }
    16% {
        transform: translateY(-20px);
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
    }
    24% {
        transform: translateY(-10px);
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.3));
    }
    100% {
        transform: translateY(0);
        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.6));
    }
}
  