input {
    color: #43494C;
    background: #FFFFFF;
    border-radius: 1.3rem;
    border: 1px solid rgb(177, 176, 176);
    height: 3rem;
    width: 100%;
    padding-left: 1.25rem;
    margin-bottom: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(131, 129, 129, 0.19);
}


.logo {
    width: 50px; 
    height: auto; 
}


.search-input-container {
    display: flex;
    align-items: center; 
    margin-left: 10px; 
    flex-grow: 1; 
}

#searchBar {
    font-family: FontAwesome, 'Open Sans', Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
    width: 100%; 
}

.searchbar-container {
    margin-top: 1rem;
    display: flex; 
    align-items: center; 
    position: relative;
}

.website-button {
    width: 9rem;
    font-family: FontAwesome, 'Open Sans', Verdana, sans-serif; 
    font-style: normal;
    font-weight: bold;
    text-decoration: none; 
    color: #6F757B; 
    display: block;
    margin-top: 5px; 
    font-size: 14px;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-radius: 15px 5px 15px 5px;
    background-color: #ffffff;
    margin-bottom: 0.125rem;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2),
    0 2px 10px 0 rgba(131, 129, 129, 0.19);
    text-align: center;
    transition-duration: 0.3s;
}

.website-button:hover {
    background-color: #6AC393;
    color: white;
    border: none;
  }

.bottom-right-button {
    position: fixed;
    bottom: 3dvh;
    right: 5%;
}

.clear-search-bar {
    position: absolute;
    right: 35px;
    top: 50%;
    margin: auto;
    transform: translateY(-55%);
    cursor: pointer;
}
