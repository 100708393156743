.tutorial {
    width: 250px;
    height: 110px;
    left: 0;
    top: 0;
    position: relative;
    background: #4FB1BA !important;
    border-color: #4FB1BA !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.35) !important;
}

.tutorial__next {
    width: 50px;
    height: 20px;
    left: 187.5px;
    top: 80px;
    position: absolute;
    background: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 2px;
    text-align: center;
    color: #4FB1BA;
    font-size: 14px;
    font-family: FontAwesome, 'Open Sans', Verdana, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.tutorial__prev {
    left: 10px;
    top: 80px;
    position: absolute;
    text-align: center;
    color: white;
    font-size: 17px;
    font-family: FontAwesome, 'Open Sans', Verdana, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.tutorial__content {
    width: 100%;
    text-align: center;
    color: black;
    font-size: 13.5px;
    font-family: FontAwesome, 'Open Sans', Verdana, sans-serif;
    font-weight: 500;
    word-wrap: 'break-word';
}

.progress-points {
    width: 50px;
    height: 10px;
    left: 100px;
    top: 87.5px;
    position: absolute;
}